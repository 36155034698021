import axios from 'axios';

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Replace with your API's base URL
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
    // "Access-Control-Allow-Headers": "Content-Type"
  }
});


// Function to set up Axios interceptors
export const setupAxiosInterceptors = (navigate) => {
  api.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        // Remove the token
        localStorage.removeItem('token'); // or sessionStorage.removeItem('token')

        // Redirect to login page
        navigate('/');
      }
      return Promise.reject(error);
    }
  );
};

export const getEmployee = (id = null, nik = null) => {
  return api.get('/api/v2/employee', {
    params: { id, nik }
  });
};


export const searchEmployees = (nik = null, email = null, phone = null) => {
  return api.get('/api/search-employees', {
    params: { nik, email, phone }
  });
};

export const redeemVoucherWithPhoto = (voucher_id, employee_id, info, file) => {
  const adminId = localStorage.getItem('admin_id')
  console.log(adminId)
  const formData = new FormData();
  formData.append('voucher_id', voucher_id);
  formData.append('employee_id', employee_id);
  formData.append('info', info);
  formData.append('file', file);
  formData.append('admin_id', adminId);

  return api.post('/api/redeem_voucher_with_photo', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem('token')}`, // Assuming the token is stored in localStorage
    }
  });
};

export const redeemVoucherWithoutPhoto = (voucher_id, employee_id, info) => {
  const adminId = localStorage.getItem('admin_id')
  return api.post('/api/redeem_voucher_without_photo', null, {
    params: { voucher_id, employee_id, info, admin_id: adminId },
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, // Assuming the token is stored in localStorage
    }
  });
};


export const getMerchants = () => {
  return api.get('/api/get/merchants');
};

export const adminLogin = (email, password) => {
  return api.post('/api/admin/login', {
    email,
    password
  });
};


export const getAdmin = (email, password) => {
  return api.get('/api/admin', {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, // Assuming the token is stored in localStorage
    }
  });
  
};

export const searchEmployees2 = (nik = null, email = null, phone = null) => {
  return api.get('/api/search-employees2', {
    params: { nik, email, phone },
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, // Assuming the token is stored in localStorage
    }
  });
};