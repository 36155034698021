import React, { useState } from 'react';
import RedeemConfirmationModal from './modal/RedeemConfirmationModal';
import PhotoCapture from '../components/PhotoCapture';
import { redeemVoucherWithPhoto } from '../networks/ApiServices';
import { useNavigate } from 'react-router-dom';
import { base64ToBlob } from '../utils/Base64ToBlobUtil';

const VoucherList = ({ vouchers, doRedeem }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedVoucher, setSelectedVoucher] = useState(null);
    const [photoCaptureIsOpen, setPhotoCaptureIsOpen] = useState(false);
    const navigate = useNavigate();

    const openModal = (voucher) => {
        setSelectedVoucher(voucher);
        setModalIsOpen(true);
      };
    
      const closeModal = () => {
        setModalIsOpen(false);
      };
      const openPhoto = (voucher) => {
        window.open(process.env.REACT_APP_API_BASE_URL+"/api/voucher_proof/"+voucher.image_proof_url, '_blank'); // '_blank' specifies to open in a new tab
      }
    
      const confirmRedeem = () => {
        if (selectedVoucher.voucher_required_photo) {
            setPhotoCaptureIsOpen(true);
        } else {
            doRedeem(selectedVoucher.voucher_id);
        }
        closeModal();
      };

      const handleCaptureConfirm = async (photo) => {
        const blob = base64ToBlob(photo, 'image/jpeg');

        try {
          await redeemVoucherWithPhoto(selectedVoucher.voucher_id, selectedVoucher.employee_id, '', blob);
          alert('Voucher redeemed successfully');
          setPhotoCaptureIsOpen(false);
          navigate(0);

        } catch (error) {
          console.log(error)
          alert(error.response.data.error);
        }
      };
    
      const cancelCapture = () => {
        setPhotoCaptureIsOpen(false);
      };

  return (
    <div>
      {photoCaptureIsOpen ? (
        <PhotoCapture
          voucherName={selectedVoucher.voucher_name}
          onCaptureConfirm={handleCaptureConfirm}
          onCancel={cancelCapture}
        />

      ) : (
        <>
          <h2>Voucher List</h2>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {vouchers.map(voucher => (
                <tr key={voucher.id}>
                  <td>{voucher.voucher_name}</td>
                  <td>{voucher.redeem ? 'Redeemed' : 'Not Redeemed'}</td>
                  <td>
                    <button 
                      onClick={() => openModal(voucher)}
                      disabled={voucher.redeem}
                    >
                      Redeem
                    </button>

                    {
                    voucher.redeem && voucher.voucher_required_photo=='1'? 
                    <button 
                      style={{marginLeft: '5px'}}
                      onClick={() => openPhoto(voucher)}>
                      {voucher.redeem && voucher.voucher_required_photo=='1'? 'View Photo':''}
                    </button>:''}

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      {selectedVoucher && (
        <RedeemConfirmationModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          onConfirm={confirmRedeem}
          voucherName={selectedVoucher.voucher_name}
        />
      )}
    </div>
  );
};

export default VoucherList;
